import React from 'react'
import './Footer.css'
import { CodeBlock } from '@styled-icons/boxicons-regular'
import {
  Twitter,
  Copyright,
  Instagram,
  Facebook,
  LinkedinBox,
  Spotify,
  Github
} from '@styled-icons/remix-line'

export default () => (
  <div>
    <footer className="footer">
      <div className="container taCenter columns">
        <div className="column" style={{ textAlign: 'left' }}>
          <Copyright size="2em" /> Copyright 2019 - {new Date().getFullYear()}{' '}
          All rights reserved. <br />
          <CodeBlock size="2em" /> by{' '}
          <a href="https://ilhamfadhil.com">Ilham Fadhil</a>.
        </div>
        <div
          className="column columnsSocial"
          style={{ justifyContent: 'flex-end' }}
        >
          <div>
            <a className="socialLogo" href="https://twitter.com/ilhamfadheel">
              <Twitter size="2em" />
            </a>
            <a className="socialLogo" href="https://instagram.com/ilhamfadheel">
              <Instagram size="2em" />
            </a>
            <a
              className="socialLogo"
              href="https://open.spotify.com/user/12159924014?si=PnFFYa0sRAGcpb4mQFy66w"
            >
              <Spotify size="2em" />
            </a>
          </div>
          <div>
            <a
              className="socialLogo"
              href="https://www.facebook.com/Ilhamfadheel/"
            >
              <Facebook size="2em" />
            </a>
            <a
              className="socialLogo"
              href="https://www.linkedin.com/in/ilhamfadheel/"
            >
              <LinkedinBox size="2em" />
            </a>
            <a className="socialLogo" href="https://github.com/ilhamfadheel">
              <Github size="2em" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  </div>
)
